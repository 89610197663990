import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Typography, Tabs, Tab, styled } from "@mui/material";
import hedgie from "../assets/h3.png";
import "../App.css";
import Skills from "./Skills";

function TabPanel(props) {
  const { children, details, position, company, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={details !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {details === index && (
        <Box disply="flex" flexdirection="column" height="100%">
          <Typography variant="h4" align="left" sx={{ color: "#00FFB3" }}>
            {position}
          </Typography>
          <Typography
            variant="subtitle2"
            align="left"
            sx={{ color: "#5C5C5C", paddingBottom: "1%" }}
          >
            @ {company}
          </Typography>
          <Typography variant="body1" align="left" sx={{ color: "#00FFB3" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  details: PropTypes.number.isRequired,
};

function allyProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Experience() {
  const StyledTab = styled(Tab)({
    "&.MuiTab-root": {
      color: "#5C5C5C",
      alignItems: "start",
    },
    "&.Mui-selected": {
      fontWeight: "bold",
      color: "white",
    },
  });

  const [details, setdetails] = React.useState(0);

  const handleChange = (event, newdetails) => {
    setdetails(newdetails);
  };
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          display: "flex",
          paddingBottom: "2%",
        }}
      >
        <Box sx={{ width: "70%", maxWidth: "70%", display: "block" }}>
          <Box
            sx={{
              width: "70%",
              maxWidth: "70%",
              display: "block",
              paddingRight: "5%",
            }}
          >
            <Typography
              variant="h2"
              component="div"
              align="left"
              sx={{ color: "#00FF33", fontWeight: "bold" }}
            >
              Experience
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              indicatorColor="None"
              value={details}
              onChange={handleChange}
              sx={{
                maxWidth: "100%",
                flexShrink: 0,
                flexGrow: 0,
                minWidth: "20%",
              }}
            >
              <StyledTab disableRipple label="2022-23" {...allyProps(0)} />
              <StyledTab disableRipple label="2020-22" {...allyProps(1)} />
              <StyledTab disableRipple label="2020" {...allyProps(2)} />
              <StyledTab disableRipple label="2018-19" {...allyProps(3)} />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "70%",
                maxWidth: "70%",
                minWidth: "30%",
              }}
            >
              <TabPanel
                details={details}
                position="Software Engineer - SDET"
                company="Bakkt"
                index={0}
              >
                Developed, refactored and maintained REST API automated test
                suites using TypeScript. Converted manual Java tests into
                automated TypeScript tests. Utilized Perfecto to identify UI
                defects and provide feedback to development teams. Ran
                regression tests and monitored results, as well as include new
                scenarios for any added features.
              </TabPanel>
              <TabPanel
                details={details}
                position="Software Developer"
                company="GM"
                index={1}
              >
                Responsible for maintaining and handling security upgrades as
                the owner of a marketing application. Developed and maintained
                one of GM’s reporting and analytics applications writing Java
                code to automate ETL jobs. Provided on-call support for any
                production issues as well as monitoring end-of-month sales data
                load. Worked with a variety of different languages and tools
                such as Java, SQL, Linux/Unix, DataStage, Autosys, Power BI,
                etc.
              </TabPanel>
              <TabPanel
                details={details}
                position="Research Assistant"
                company="Temple University"
                index={2}
              >
                Worked to develop a prognostic utility asset health monitor
                using deep learning image recognition. Applied TensorFlow,
                Keras, ResNet, and Mask R-CNN to train a model using Google
                Street View images. Implemented an image processing Python
                script to create training sets by splitting, sizing, and
                organizing the images
              </TabPanel>
              <TabPanel
                details={details}
                position="UX Researcher"
                company="BoxSwap"
                index={3}
              >
                Analyzed user data to propose the implementation of financial
                focused features. Created partnerships with multiple NFT
                applications. Communicated and collaborated with business
                partners to integrate their technology into the application.
              </TabPanel>
            </Box>
          </Box>
        </Box>
        <img id="round_img" src={hedgie} alt="hedgie"></img>
      </Box>
      <Skills />
    </Box>
  );
}
