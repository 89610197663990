import React from "react";
import { Box } from "@mui/system";
import { styled } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

export default function IconLinks() {
  const iconSize = "3vw";
  const iconStyles = {
    fontSize: iconSize,
    color: "white",
  };
  const StyledBox = styled(Box)({
    width: iconSize,
    height: iconSize * 3,
    position: "fixed",
    bottom: "0.5vw",
    left: "0.5vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  });
  return (
    <StyledBox>
      <a href="https://github.com/m1ke98">
        <GitHubIcon style={iconStyles} />
      </a>
      <a href="https://www.linkedin.com/in/michael-d-argenio-155b17127/">
        <LinkedInIcon style={iconStyles} />
      </a>
      <a href="mailto:mdargenio14@gmail.com">
        <EmailIcon style={iconStyles} />
      </a>
    </StyledBox>
  );
}
