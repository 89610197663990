import React, { useEffect, useState } from "react";
import useWeb3Modal from "./hooks/useWeb3Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./App.css";
import { Box } from "@mui/material";

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import AboutMe from "./components/AboutMe";
import Experience from "./components/Experience";
import IconLinks from "./components/IconLinks";
import Projects from "./components/Projects";
//import Message from './components/Message';

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("");
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);

        try {
          // Resolve the ENS name for the first account.
          const name = await provider.lookupAddress(accounts[0]);

          // Render either the ENS name or the shortened account address.
          if (name) {
            setRendered(name);
          } else {
            setRendered(
              account.substring(0, 6) + "..." + account.substring(36)
            );
          }
        } catch (err) {
          setRendered(account.substring(0, 6) + "..." + account.substring(36));
        }
      } catch (err) {
        setAccount("");
        setRendered("");
        console.error(err);
        return;
      }
    }
    fetchAccount();
  }, [provider, account, setAccount, setRendered]);

  function web3Modal() {
    if (!provider) {
      /* catch prevents errors when user closes wallet modal*/
      loadWeb3Modal()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    } else {
      logoutOfWeb3Modal()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Navbar web3Modal={web3Modal} rendered={rendered} />
        <IconLinks />
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "13%",
            marginRight: "13%",
          }}
        >
          <Home />
        </Box>
        <br></br>
        <div id="about">
          <Box
            id="about"
            sx={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              marginLeft: "13%",
              marginRight: "13%",
            }}
          >
            <AboutMe />
          </Box>
        </div>
        <br></br>
        <div id="experience">
          <Box
            id="experience"
            sx={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              marginLeft: "13%",
              marginRight: "13%",
            }}
          >
            <Experience />
          </Box>
        </div>
        <div id="projects">
          <Box
            id="projects"
            sx={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              marginLeft: "13%",
              marginRight: "13%",
            }}
          >
            <Projects />
          </Box>
        </div>
        {/*
        <Box sx={{ margin: '10%' }}>
          <Message />
        </Box>
        */}
        {/* <header className="App-header">
            <p>
              Coming Soon!
            </p>
          </header> */}
      </ThemeProvider>
    </div>
  );
}

export default App;
