import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import logo from "../assets/logo100.svg";

export default function Navbar({ web3Modal, rendered }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={0} sx={{ background: "none", mt: 1 }}>
        <Toolbar>
          <Box sx={{ display: "flex", flexGrow: 1, ml: 2 }}>
            <img src={logo} alt="nav-logo" height="48" width="auto" />
          </Box>
          <Link
            underline="hover"
            href="#about"
            sx={{ mr: 4, color: "#00FFB3" }}
          >
            About Me
          </Link>
          <Link
            underline="hover"
            href="#experience"
            sx={{ mr: 4, color: "#00FFB3" }}
          >
            Experience
          </Link>
          <Link
            underline="hover"
            href="#projects"
            sx={{ mr: 4, color: "#00FFB3" }}
          >
            Projects
          </Link>
          <Tooltip
            title={
              rendered && rendered !== ""
                ? "Disconnect Wallet"
                : "Connect Wallet"
            }
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{ mr: 2 }}
              onClick={web3Modal}
            >
              {rendered && rendered !== "" ? rendered : "Connect Wallet"}
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
