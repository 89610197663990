import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import "../App.css";
import poe from "../assets/poe.png";
import jonHerbert from "../assets/jonHerbert.png";
import moreHorizontal from "../assets/moreHorizontal.svg";

export default function Projects() {
  const paperStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    width: "200px",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#0D0E12",
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "100%", display: "flex" }}>
      <Box
        sx={{
          width: "70%",
          maxWidth: "70%",
          display: "block",
          paddingLeft: "5%",
        }}
      >
        <Typography
          variant="h2"
          align="left"
          sx={{ color: "#00FF33", fontWeight: "bold" }}
        >
          Projects
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={1}
            sx={{ mt: 6 }}
            rowSpacing={1}
            columnSpacing={{ xs: 3, sm: 4, md: 6 }}
          >
            {[
              {
                image: jonHerbert,
                text: "jon-herbert.com",
                url: "https://www.jon-herbert.com/",
              },
              {
                image: poe,
                text: "Proof of Experience",
                url: "https://github.com/m1ke98/ETHOnline",
              },
              {
                image: moreHorizontal,
                text: "More...",
                url: "https://github.com/m1ke98/",
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={8} md={4} key={index}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <Paper className="paper-hover" sx={paperStyles}>
                    <img
                      src={item.image}
                      alt={item.text}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Paper>
                </a>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
