import React from "react";
import { Box } from "@mui/system";
import { Typography, Grid, Card, CardContent, Chip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";

export default function Skills() {
  const theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: "#0B0C10",
            boxShadow: "none",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: "#00FFB3",
            backgroundColor: "#212121",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="h6"
                  color="White"
                  sx={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  Programming Languages
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Chip label="JavaScript" size="small" />
                  <Chip label="TypeScript" size="small" />
                  <Chip label="Python" size="small" />
                  <Chip label="Solidity" size="small" />
                  <Chip label="Java" size="small" />
                  <Chip label="HTML" size="small" />
                  <Chip label="CSS" size="small" />
                  <Chip label="SQL" size="small" />
                  <Chip label="Unix/Linux" size="small" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="h6"
                  color="White"
                  sx={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  Libraries & Frameworks
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Chip label="React.js" size="small" />
                  <Chip label="Material UI" size="small" />
                  <Chip label="Node.js" size="small" />
                  <Chip label="Mocha" size="small" />
                  <Chip label="Ethers.js" size="small" />
                  <Chip label="Web3.js" size="small" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ backgroundColor: "#0B0C10" }}>
              <CardContent>
                <Typography
                  variant="h6"
                  color="White"
                  sx={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  Tools & Platforms
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Chip label="Git" size="small" />
                  <Chip label="Azure" size="small" />
                  <Chip label="Jira" size="small" />
                  <Chip label="Postman" size="small" />
                  <Chip label="Jenkins" size="small" />
                  <Chip label="Perfecto" size="small" />
                  <Chip label="Hardhat" size="small" />
                  <Chip label="Alchemy" size="small" />
                  <Chip label="DataStage" size="small" />
                  <Chip label="Autosys" size="small" />
                  <Chip label="Power BI" size="small" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
