import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

export default function Home() {
  return (
    <Box sx={{ width: "70%", maxWidth: "70%", display: "block" }}>
      <Typography
        variant="body1"
        component="div"
        align="left"
        sx={{ color: "#FFFFFF" }}
      >
        Hi, my name is
      </Typography>
      <Typography
        variant="h2"
        component="div"
        align="left"
        sx={{ color: "#00FF33", fontWeight: "bold" }}
      >
        Michael D'Argenio.
      </Typography>
      {/* <Typography variant="h3" component="div" align="left" sx={{ color: '#4DFF00', mb: 1 }}>
                I build decentralized applications.
            </Typography> */}
      <Typography
        variant="body1"
        component="div"
        align="left"
        sx={{ color: "#00FFB3" }}
      >
        I'm a software engineer focused on blockchain development, currently
        buidling cool stuff on Ethereum.
      </Typography>
    </Box>
  );
}
