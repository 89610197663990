import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import hedgie from "../assets/h1.png";
import "../App.css";

export default function AboutMe() {
  return (
    <Box sx={{ width: "100%", maxWidth: "100%", display: "flex" }}>
      <img id="round_img" src={hedgie} alt="hedgie"></img>
      <Box
        sx={{
          width: "70%",
          maxWidth: "70%",
          display: "block",
          paddingLeft: "5%",
        }}
      >
        <Typography
          variant="h2"
          align="left"
          sx={{ color: "#00FF33", fontWeight: "bold" }}
        >
          About Me
        </Typography>
        <Typography variant="body1" align="left" sx={{ color: "#00FFB3" }}>
          I'm a mechanical engineer by degree and a software engineer by choice.
          My passion for programming and obssesion with blockchain began in 2017
          when I first started learning about smart contracts, NFTs and DeFi.
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{ color: "#00FFB3", paddingTop: "5%" }}
        >
          My primary focus now is building decentralized applications on
          Ethereum.
        </Typography>
      </Box>
    </Box>
  );
}
