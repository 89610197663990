import { Web3Provider } from '@ethersproject/providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Web3Modal from 'web3modal';

const NETWORK = "mainnet";

function useWeb3Modal(config ={}) {
    const [provider, setProvider] = useState();
    const [autoLoaded, setAutoLoaded] = useState(false);
    const { autoLoad = true, network = NETWORK } = config;

    const web3Modal = useMemo(() => {
        return new Web3Modal({
            network,
            cacheProvider: true,
            providerOptions: {
            }
        })
    }, [network]);

    const loadWeb3Modal = useCallback(async () => {
        const newProvider = await web3Modal.connect();
        setProvider(new Web3Provider(newProvider));
    }, [web3Modal]);
    
    const logoutOfWeb3Modal = useCallback(
        async function () {
            await web3Modal.clearCachedProvider();
            window.location.reload();
        },
        [web3Modal],
    );

    useEffect(() => {
        if (autoLoaded && !autoLoaded && web3Modal.cachedProvider) {
            loadWeb3Modal();
            setAutoLoaded(true);
        }
    }, [autoLoad, autoLoaded, loadWeb3Modal, setAutoLoaded, web3Modal.cachedProvider]);
    return [provider, loadWeb3Modal, logoutOfWeb3Modal];
}

export default useWeb3Modal;